<h2 mat-dialog-title>Certificaat toevoegen</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-12 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Certificaat</label>
                <input  (change)="onFileSelected($event)" type="file" accept="application/pdf, image/jpeg, , image/jpg" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Titel</label>
                <input [(ngModel)]="description" placeholder="..." type="text" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Behaald op</label>
                <input [(ngModel)]="obtained" type="date" class="form-control form-control-lg" />
            </div>
        </div>

        <div class="col-md-6 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Vervalt op</label>
                <input [(ngModel)]="expires" type="date" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <hr>
    <div class="flex">
        <button mat-button [mat-dialog-close]="true">Annuleren</button>
        <button (click)="save()" [disabled]="!obtained || !expires || !file || !description || loading" mat-stroked-button>Toevoegen</button>
    </div>
</mat-dialog-content>