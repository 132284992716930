<section class="theorie mt-2 mt-lg-5 px-3 px-md-0">
    <div class="container breadcrumb">
        <div class="row">
            <div class="col-12">
                Dashboard &rsaquo; Certificaten
            </div>
        </div>
    </div>
    <div class="container mb-8">
        <div class="row">
            <!-- Content -->
            <div class="col-lg-9 text_page border border-white bg_white box_shadow_1 border_radius_std">
                <div class="inside_h1 d-flex align-items-center mb-4">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">workspace_premium</span>
                    </div>
                    <h1 class="ms-3 mt-2">Certificaten</h1>
                </div>
                <div class="mx-3 mx-lg-5">
                    <div class="mb-4 pb-3">
                        <input [(ngModel)]="searchVal" type="text" class="input form-control" placeholder="Zoeken...">
                        <div *ngIf="getManualCertCount() > 0" class="mt-5">
                            <h2>OVERIGE CERTIFICATEN</h2>
                            <ng-container *ngFor="let certificate of certificates">
                                <div class="py-3 row horizontal_line_lightgrey_std"
                                    *ngIf="canShow(certificate.description) && certificate.manually_uploaded">
                                    <div class="col-6 d-flex">
                                        <div class="progress-circle-ct p-2 me-3">
                                            <div class="circular-trophy bg_lightgrey_std">
                                                <span
                                                    class="material-symbols-outlined color_green_std">workspace_premium</span>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <h3><span class="header_font_light">{{certificate['description']}}</span></h3>
                                            Certificaat behaald op: {{certificate['created_at_text']}}
                                        </div>
                                    </div>
                                    <div class="col-6 mt-2">
                                        <a href="{{apiBaseURL}}/me/certificate/{{certificate.external_hash}}" class="btn btn-light btn-sm mr-2">Certificaat bekijken</a>
                                        <a (click)="deleteCertificate(certificate)"
                                        class="btn btn-dark btn-sm">Verwijderen</a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <h2 class="mt-3">HDGM E-Learning certificaten</h2>

                        <ng-container *ngFor="let certificate of certificates">
                            <div class="py-3 row horizontal_line_lightgrey_std"
                                *ngIf="canShow(certificate.description) && !certificate.manually_uploaded">
                                <div class="col-6 d-flex">
                                    <div class="progress-circle-ct p-2 me-3">
                                        <div class="circular-trophy bg_lightgrey_std">
                                            <span
                                                class="material-symbols-outlined color_green_std">workspace_premium</span>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <h3><span class="header_font_light">{{certificate['description']}}</span></h3>
                                        Certificaat behaald op: {{certificate['created_at_text']}}
                                    </div>
                                </div>
                                <div class="col-6 mt-2">
                                    <a href="{{apiBaseURL}}/me/certificate/{{certificate.external_hash}}"
                                        target="_blank" class="btn btn-light btn-sm mr-2">Certificaat bekijken</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- Index menu desktop -->
            <div class="col-lg-3 scroll_index_menu_wrap d-none d-lg-block">
                <div class="scroll_index_menu g-0 bg_lightgrey_dark box_shadow_1">
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">workspace_premium</span></div>
                        <h2 class="ms-3 mt-2">Certificaat uploaden</h2>
                    </div>
                    <div class="mx-4 mb-4">
                      <p>Upload je overige behaalde resultaten in de HDGM E-Learning omgeving. Zo heb je alles overzichtelijk bij de hand.</p>
                      <span (click)="uploadFile()" class="btn btn-light btn-sm">Upload certificaat</span>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">school</span></div>
                        <h2 class="ms-3 mt-2">Verbreed je kennis!</h2>
                    </div>
                    <div class="mx-4 mb-4">
                        <p>Verbreed je kennis en bevoegdheden! Schrijf je in voor andere E-learnings en behaal je
                            certifcaat via ons adaptive learning programma</p>
                        <a href="./modules"><span class="btn btn-light btn-sm">Bekijk alle modules</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<script>
    document.addEventListener("DOMContentLoaded", function () {
        var lastScrollTop = 0;
        var scrollMenu = document.querySelector(".scroll_index_menu");

        window.addEventListener("scroll", function () {
            var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                // Downscroll code
                scrollMenu.style.borderRadius = "18px 18px 18px 18px";
            } else {
                // Upscroll code
                scrollMenu.style.borderRadius = "0px 18px 18px 18px";
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        });
    });
</script>