<h2 mat-dialog-title>Wachtwoord wijzigen</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-6 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Nieuw wachtwoord</label>
                <input [(ngModel)]="password" type="password" class="form-control form-control-lg" />
            </div>
        </div>

        <div class="col-md-6 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Herhaal wachtwoord</label>
                <input [(ngModel)]="passwordRepeat" type="password" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <hr>
    <div class="flex">
        <button mat-button [mat-dialog-close]="true" >Annuleren</button>
        <button (click)="save()" [disabled]="passwordRepeat !== password || password == ''" mat-stroked-button [mat-dialog-close]="true">Opslaan</button>
    </div>
</mat-dialog-content>