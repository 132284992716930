import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-recovery',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './password-recovery.component.html',
  styleUrl: './password-recovery.component.scss'
})
export class PasswordRecoveryComponent {
  loading = false;

  showError = false;
  errorMessage = "";

  password = "";
  passwordRepeat = "";

  hash: any = "";

  step = 0;

  message = "";

  constructor(private route: ActivatedRoute, private apiService: ApiService, private storageService: StorageService, private router: Router) {
    this.hash = this.route.snapshot.paramMap.get('hash');
  }


  displayError(message: string) {
    this.errorMessage = message;
    this.showError = true;
    setTimeout(() => {
      this.showError = false;
    }, 3000);
  }

  isValidEmail(email: string) {
    // Regular expression to match email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex and return the result
    return emailRegex.test(email);
  }

  validateCode() {

  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  setPassword() {
    if (this.password == this.passwordRepeat) {

      this.toggleLoading();

      this.apiService.post('/password-recoveries/' + this.hash + '/set-password', {
        password: this.password,
        password_repeat: this.passwordRepeat
      }).then((response: any) => {
        console.log(response);
        this.message = response.message;
        this.step = 1;
        setTimeout(() => {
          window.open("/login", "_self");
        }, 10000);
        this.toggleLoading();
      }).catch((err) => {
        this.toggleLoading();
        this.displayError(err.error.message);
      });
    }
  }
}
