import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';

import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-new-subscription',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatInputModule, MatFormFieldModule],
  templateUrl: './new-subscription.component.html',
  styleUrl: './new-subscription.component.scss'
})
export class NewSubscriptionComponent {

  subscriptionOptions: any = [];
  selectedSubscription: any;
  
  errorMessage = "";
  showError = false;

  constructor(private apiService: ApiService) {
    this.apiService.get("/register/subscriptions").then((data) => {
      this.subscriptionOptions = data;
    });
  }

  subscribe() {
    this.apiService.post("/me/subscriptions/start", {
      subscription_id: this.selectedSubscription,
    }).then((data: any) => {
      window.location.href = data.redirectUrl;
    }).catch((err) => {
      console.log(err);
      this.errorMessage = err.error.message;
      this.showError = true;
    });
  }

  toDutchEurFormat(number: any) {
    return new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }
}
