<section class="mt-3 mt-lg-5 mb-5 px-3 px-md-0">
    <div class="container g-0 mb-2 mb-lg-3">
        <h1 class="d-inline me-4">Dashboard</h1><span class="d-none d-lg-block">Goedemorgen {{profile['first_name']}}
            {{profile['last_name']}}, welkom terug in je
            persoonlijke leeromgeving</span>
    </div>
    <div class="container border border-white bg_lightgrey_std box_shadow_2 border_radius_std mb-5">
        <div class="row">
            <div class="col-lg-3">
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">account_box</span></div>
                    <h2 class="ms-3 mt-2">Mijn gegevens</h2>
                </div>
                <div class="my-2 listPadding">
                    <ul class="clean_list mb-3">
                        <li *ngIf="profile['company']">{{profile['company']}}</li>
                        <li>{{profile['first_name']}} {{profile['last_name']}}</li>
                        <li>{{profile['address']}}</li>
                        <li>{{profile['zipcode']}} {{profile['city']}}</li>
                        <li>{{profile['country']}}</li>
                    </ul>
                    <a class="btn btn-light mb-3" [href]="['/profile']">Gegevens wijzigen</a>
                </div>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">card_membership</span></div>
                    <h2 class="ms-3 mt-2">Mijn abonnementen</h2>
                </div>
                <div class="my-2 listPadding">

                    <ng-container *ngFor="let subscription of subscriptions">
                        <p *ngIf="subscription.is_active" class="mb-3">
                            {{subscription.subscription.name}}<br />
                            Looptijd: {{subscription.start_at_text}} t/m {{subscription.expired_at_text}}
                        </p>
                    </ng-container>
                    <a class="btn btn-light mb-3" [href]="['/profile']">Abonnementen wijzigen</a>
                </div>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">workspace_premium</span></div>
                    <h2 class="ms-3 mt-2">Actuele certificaten</h2>
                </div>
                <div class="my-2 listPadding">
                    <p class="mb-1" *ngFor="let certificate of certificates">
                        <b>{{certificate['description']}}</b><br />
                        Behaald: {{certificate['obtained_at_text']}}
                    </p>
                    <a class="btn btn-light mb-3" [href]="['/certificates']">Naar alle certificaten</a>
                </div>
            </div>
            <div
                class="col-lg-5 vertical_line_white_desktop horizontal_line_white_mobile d-flex flex-column justify-content-between">
                <div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">school</span></div>
                        <h2 class="ms-3 mt-2">Recente modules</h2>
                    </div>
                    <div class="mt-2 listPadding">
                        <div class="d-flex mb-4" *ngIf="!modules || modules.length < 1">
                            <p class="py-1 text-center">Nog geen module gestart. Bekijk alle <a
                                    style="text-decoration: underline;" [href]="['/modules']">modules </a>.</p>
                        </div>
                        <div class="d-flex mb-4" *ngFor="let module of modules">
                            <app-progress-circle [score]="module.score"></app-progress-circle>
                            <div class="ms-3 ms-lg-5  mt-4">
                                <h3 class="mb-2">{{module.module.title}}</h3>

                                <a *ngIf="module.status !== 'FINISHED'" class="d-block"
                                    [href]="['/module/' + module.id + '/question']">&raquo;
                                    Toetsing hervatten</a>

                                <a *ngIf="module.status == 'FINISHED'" class="d-block"
                                    [href]="['/module/' + module.id]">&raquo;
                                    Toetsing afgerond</a>

                                <a *ngIf="module.status == 'FINISHED'" class="d-block"
                                    [href]="['/certificates']">&raquo;
                                    Certificaat behaald</a>

                                <a *ngIf="module.status == 'FINISHED'" class="d-block"
                                    [href]="['/module/' + module.id]">&raquo;
                                    Opnieuw toetsen</a>

                                <a *ngIf="module.status !== 'FINISHED'" class="d-block"
                                    [href]="['/module/' + module.id]">&raquo; Theorie lezen</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-4 mb-4">
                    <a class="btn btn-light w-100" [href]="['/modules']">Bekijk al mijn modules </a>
                </div>
            </div>
            <div
                class="col-lg-4 d-flex flex-column justify-content-between bg_lightgrey_darker vertical_line_white_desktop horizontal_line_white_mobile border_radius_std_topRight_bottomRight_desktop border_radius_std_bottomLeft_bottomRight_mobile">
                <div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">event</span></div>
                        <h2 class="ms-3 mt-2">Mijn events</h2>
                    </div>
                    <div (click)="openEvent(event.event, true)" *ngFor="let event of userEvents"
                        class="mt-2 listPadding mb-4 d-flex pointer">
                        <div class="calendar_date border-end border-white h-100 me-3">
                            <div class="h2_text mt-n1">{{getDayFromDateString(event.event.start_at)}}</div>
                            <div class="mt-n2">{{getMonthFromDateString(event.event.end_at)}}</div>
                        </div>
                        <div class="calendar_info">
                            <div class=""><b>{{event.event.title}}</b></div>
                            <div class="lh-sm">{{event.event.description}}</div>
                        </div>
                    </div>
                    <div class="horizontal_line_white"></div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">calendar_month</span>
                        </div>
                        <h2 class="ms-3 mt-2">Alle events</h2>
                    </div>

                    <ng-container *ngFor="let event of events">
                        <div (click)="openEvent(event, false)" class="mt-2 listPadding mb-4 d-flex pointer">
                            <div class="calendar_date border-end border-white h-100 me-3">
                                <div class="h2_text mt-n1">{{getDayFromDateString(event.start_at)}}</div>
                                <div class="mt-n2">{{getMonthFromDateString(event.end_at)}}</div>
                            </div>
                            <div class="calendar_info">
                                <div class=""><b>{{event.title}}</b></div>
                                <div class="lh-sm">{{event.description}}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>