import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiBaseURL: string = '';
  private loading: boolean = false;

  constructor(private http: HttpClient, private router: Router, private storageService: StorageService) {
    this.apiBaseURL = environment.api_url;
  }

  public getApiBaseURL() {
    return this.apiBaseURL;
  }

  public getLoadingStatus() {
    return this.loading;
  }

  private setLoadingStatus(status: boolean) {
    this.loading = status;
  }

  public get(endpoint: string, params: any = {}) {
    this.setLoadingStatus(true);
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    httpHeaders = httpHeaders.set('Authorization', "Bearer " + this.storageService.getAccessToken());

    try {
      return new Promise((resolve, reject) => {
        let apiURL = this.getApiBaseURL() + endpoint;
        if (Object.values(params).length > 0) {
          apiURL += '?' + new URLSearchParams(params).toString();
        }

        this.http.get(apiURL, {
          headers: httpHeaders
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            }
          ).catch((err) => {
            if (err.status === 401 && !window.location.pathname.includes('/login')) {
              window.location.href = '/login';
              return;
            }

            reject(err);
          });
      });
    } finally {
      this.setLoadingStatus(false);
    }
  }

  public post(endpoint: string, data: any) {
    this.setLoadingStatus(true);
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders.set('Authorization', "Bearer " + this.storageService.getAccessToken());

    try {
      return new Promise((resolve, reject) => {
        let apiURL = this.getApiBaseURL() + endpoint;
        this.http.post(apiURL, data, {
          headers: httpHeaders
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            }
          )
          .catch((err) => {
            if (err.status === 401 && !window.location.pathname.includes('/login')) {
              window.location.href = '/login';
              return;
            }

            reject(err);
          });
      });
    } finally {
      this.setLoadingStatus(false);
    }
  }


  public login(data: any) {
    this.setLoadingStatus(true);
    let httpHeaders = new HttpHeaders();

    try {
      return new Promise((resolve, reject) => {
        let apiURL = this.getApiBaseURL() + "/login";
        this.http.post(apiURL, data, {
          headers: httpHeaders
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            }
          )
          .catch((err) => {
            if (err.status === 403 && !window.location.pathname.includes('/login')) {
              window.location.href = '/login';
              return;
            }

            reject(err);
          });
      });
    } finally {
      this.setLoadingStatus(false);
    }
  }

  public put(endpoint: string, data: any, skipAuth: boolean = false) {
    this.setLoadingStatus(true);
    let httpHeaders = new HttpHeaders();

    httpHeaders = httpHeaders.set('Authorization', "Bearer " + this.storageService.getAccessToken());

    try {
      return new Promise((resolve, reject) => {
        let apiURL = this.getApiBaseURL() + endpoint;
        this.http.put(apiURL, data, {
          headers: httpHeaders
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            }
          )
          .catch((err) => {
            if (!skipAuth && err.status === 403 && !window.location.pathname.includes('/login')) {
              window.location.href = '/login';
              return;
            }

            reject(err);
          });
      });
    } finally {
      this.setLoadingStatus(false);
    }
  }

  public delete(endpoint: string, skipAuth: boolean = false) {
    this.setLoadingStatus(true);
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    httpHeaders = httpHeaders.set('Authorization', "Bearer " + this.storageService.getAccessToken());

    try {
      return new Promise((resolve, reject) => {
        let apiURL = this.getApiBaseURL() + endpoint;
        this.http.delete(apiURL, {
          headers: httpHeaders
        })
          .toPromise()
          .then(
            res => {
              resolve(res);
            }
          ).catch((err) => {
            if (!skipAuth && err.status === 403 && !window.location.pathname.includes('/login')) {
              window.location.href = '/login';
              return;
            }

            reject(err);
          });
      });
    } finally {
      this.setLoadingStatus(false);
    }
  }
}
