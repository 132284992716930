<div id="loader" [class]="{'hidden': !loading}">
  <div class="spinner-border text-white" role="status">
  </div>
</div>

<nav *ngIf="showNav()" class="desktop_menu d-none d-lg-block position-absolute top-0 mt-5">
  <a *ngIf="showBackButton()" (click)="goBack()"
    class="desktop_menu_back d-flex flex-column justify-content-center horizontal_line_white_std bg_darkgrey_std text-white py-2 pointer">
    <span class="material-symbols-outlined text-center text-white">reply</span>
    <div class="text-center">Terug</div>
  </a>
  <a [href]="['/']" class="d-flex flex-column justify-content-center horizontal_line_white_std py-2">
    <span class="material-symbols-outlined text-center">space_dashboard</span>
    <div class="text-center">Dashboard</div>
  </a>
  <a [href]="['/profile']" class="d-flex flex-column justify-content-center horizontal_line_white_std py-2">
    <span class="material-symbols-outlined text-center">person</span>
    <div class="text-center">Profiel</div>
  </a>
  <a [href]="['/modules']" class="d-flex flex-column justify-content-center horizontal_line_white_std py-2">
    <span class="material-symbols-outlined text-center">school</span>
    <div class="text-center">Modules</div>
  </a>
  <a [href]="['/certificates']" class="d-flex flex-column justify-content-center horizontal_line_white_std pt-2 pb-3">
    <span class="material-symbols-outlined text-center">workspace_premium</span>
    <div class="text-center">Certificaten</div>
  </a>
  <a [href]="['/logout']" class="d-flex flex-column justify-content-center pt-2 pb-3">
    <span class="material-symbols-outlined text-center">logout</span>
    <div class="text-center">Uiloggen</div>
  </a>
</nav>

<nav *ngIf="showNav()"
  class="menu_mobile d-block d-lg-none position-fixed d-flex justify-content-evenly w-100 bottom-0 bg_white box_shadow_up">
  <a [href]="['/']" class="d-flex flex-column align-items-center py-3"><span
      class="material-symbols-outlined mb-2">space_dashboard</span>Dashboard</a>
  <div class="vertical_line_lightgrey_dark"></div>
  <a [href]="['/modules']" class="d-flex flex-column align-items-center py-3"><span
      class="material-symbols-outlined mb-2">school</span>Modules</a>
  <div class="vertical_line_lightgrey_dark"></div>
  <a [href]="['/profile']" class="d-flex flex-column align-items-center py-3"><span
      class="material-symbols-outlined mb-2">person</span>Profiel</a>
  <div class="vertical_line_lightgrey_dark"></div>
  <a [href]="['/certificates']" class="d-flex flex-column align-items-center py-3"><span
      class="material-symbols-outlined mb-2">workspace_premium</span>Certificaten</a>
</nav>

<div class="contentContainer" [ngClass]="{'development': isDevelopmentEnvironment}">
  <router-outlet></router-outlet>
</div>