<section class="vh-100">
    <div class="container pb-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-12">
                <div class="card" style="border-radius: 1rem;" *ngIf="loading == true">
                    <div style="text-align:center;padding:50px 0;">
                        <div class="loader"></div>
                        <p>Betaling wordt gecontroleerd</p>
                    </div>
                </div>
                <div *ngIf="item">
                    <div class="card success" style="border-radius: 1rem;" *ngIf="item['paid'] == true">
                        <div style="text-align:center;padding:50px 0;">
                            <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></div>
                            <p>Betaling akkoord</p>
                        </div>
                    </div>
                    <div class="card warning" style="border-radius: 1rem;" *ngIf="item['paid'] == false">
                        <div style="text-align:center;padding:50px 0;">
                            <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg></div>
                            <p style="max-width: 400px;margin:0 auto;">Er is iets misgegaan bij het verwerken van de betaling, neem contact op met <a href="https://hdgm.nl/contact" target="_blank" style="text-decoration: underline;">HDGM</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>