import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-event-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatInputModule, MatFormFieldModule],
  templateUrl: './event-dialog.component.html',
  styleUrl: './event-dialog.component.scss'
})
export class EventDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private dialog: MatDialog) {
  }

  showError = false;
  errorMessage = "";

  extractDate(dateTimeStr: any) {
    // Split the date and time parts
    let [date, time] = dateTimeStr.split(' ');
    return date;
  }

  extractTime(dateTimeStr: any) {
    // Split the date and time parts
    let [date, time] = dateTimeStr.split(' ');
    // Extract only the hour and minutes part from the time
    let [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
  }


  saveChanges() {
    // this.apiService.put("/me", this.data);
  }

  attend() {
    const self = this;
    this.apiService.post("/me/event/attend", {
      event_id: this.data.id,
      comment: this.data.comment
    }).then((data: any) => {
      // self.dialog.closeAll();
      window.open(data.redirectUrl, "_self");
    }).catch((err) => {
      this.errorMessage = err.error.message;
      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 3000);
    });
  }
}
