<section class="mt-3 mt-lg-5 mb-5 px-3 px-md-0">
    <div class="container g-0 mb-2 mb-lg-3">
        <!-- <h1 class="d-inline me-3">Mijn profiel</h1> -->
        <div class="container breadcrumb">
            <div class="row">
                <div class="col-12">
                    Dashboard &rsaquo; Mijn profiel
                </div>
            </div>
        </div>
    </div>
    <div class="container border border-white bg_lightgrey_std box_shadow_2 border_radius_std mb-5">
        <div class="row">
            <!-- First column -->
            <div class="col-lg-3">
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">account_box</span></div>
                    <h2 class="ms-3 mt-2">Mijn gegevens</h2>
                </div>

                <div class="my-2 ms-4">
                    <h3>Persoongegevens</h3>
                    <ul class="clean_list mb-3">
                        <li>{{profile.first_name}} {{profile.last_name}}</li>
                        <li>{{profile.address}}</li>
                        <li>{{profile.zipcode}} {{profile.city}}</li>
                    </ul>
                    <h3>Contactgegevens</h3>
                    <ul class="clean_list mb-3">
                        <li>E: {{profile.email}}</li>
                    </ul>
                    <a class="btn btn-light mb-3 mr-2" (click)="openEditDialog()">Profiel wijzigen</a>
                    <a class="btn btn-light mb-3" (click)="updatePassword()">Wachtwoord wijzigen</a>
                </div>
            </div>
            <!-- Second column -->
            <div class="col-lg-4 vertical_line_white_desktop horizontal_line_white_mobile">
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">card_membership</span></div>
                    <h2 class="ms-3 mt-2">Mijn abonnementen</h2>
                </div>
                <div class="scroll_list_abonnementen">
                    <div class="my-2 ms-4" *ngFor="let subscription of subscriptions">
                        <h3 class="mb-3">{{subscription.subscription.name}} (
                            <span *ngIf="subscription.is_active">Actief</span>
                            <span *ngIf="!subscription.is_active">Gestopt</span>
                            )
                        </h3>
                        <p class="mb-0">Looptijd: {{formatDateTime(subscription.start_at)}} <ng-container
                                *ngIf="subscription.expired_at">t/m
                                {{formatDateTime(subscription.expired_at)}}</ng-container></p>
                        <p>Verlengdatum: {{subscription.expired_at_text}}</p>
                        <a (click)="cancelSubscription(subscription)" class="btn btn-light mb-3" *ngIf="subscription.is_active">Stopzetten</a>
                        <a class="btn btn-light mb-3" *ngIf="!subscription.is_active">Gestopt</a>
                    </div>
                    <div class="my-2 ms-4">
                        <a (click)="newSubscription()" class="btn btn-dark mb-3">Nieuw abonnement starten</a>
                    </div>
                </div>
            </div>
            <!-- Third column -->
            <div
                class="col-lg-5 d-flex flex-column justify-content-between bg_lightgrey_darker vertical_line_white_desktop horizontal_line_white_mobile border_radius_std_topRight_bottomRight_desktop border_radius_std_bottomLeft_bottomRight_mobile">
                <div>
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">draft</span></div>
                        <h2 class="ms-3 mt-2">Mijn facturen</h2>
                    </div>
                    <div class="scroll_list_facturen">

                        <div class="mx-4" *ngIf="invoices.length == 0">
                            <p>Nog geen factureren beschikbaar.</p>
                        </div>

                        <ng-container *ngFor="let invoice of invoices">
                            <div class="mx-4 mb-3" style="padding-top:14px;">
                                <h3 class="mb-1">Factuur <small>#{{invoice['number']}}</small></h3>
                                <div class="d-flex justify-content-between">
                                    <p class="mb-2">
                                        Factuur datum: {{invoice['created_at_text']}}<br />
                                        Bedrag: &euro; {{invoice['total_incl']}} <span *ngIf="invoice['is_paid']">(Betaald)</span>
                                    </p>
                                    <span class="btn btn-light h-100" (click)="download(invoice)">Factuur downloaden</span>
                                </div>
                            </div>
                            <div class="horizontal_line_white"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>