<h2 mat-dialog-title>Geen toegang</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-12">
            <p>Je account is momenteel inactief, je hebt geen toegang tot de modules en lessen.</p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Terug naar dashboard</button>
</mat-dialog-actions>