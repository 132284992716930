import { CanActivateFn } from '@angular/router';
import { StorageService } from './services/storage.service';
import {inject} from '@angular/core';

export const authGuardGuard: CanActivateFn = (route, state) => {
  
  const storageService = inject(StorageService);

  if (storageService.getAccessToken() == 'null') {
    window.location.href = "/login";
    return false;
  }
  return true;
};
