import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-certificate',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatInputModule, MatFormFieldModule],
  templateUrl: './upload-certificate.component.html',
  styleUrl: './upload-certificate.component.scss'
})
export class UploadCertificateComponent {

  public obtained: any = "";
  public expires: any = "";
  public description: any = "";
  public file: any;

  loading: any = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private dialog: MatDialog) {
  }

  readFile() {
    if (this.file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // The file's text will be printed here
        console.log(reader.result);
      };
      reader.readAsText(this.file);
    }
  }

  save() {
    if (!this.file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('description', this.description);
    formData.append('file', this.file, this.file.name); // Append the file and its name
    formData.append('obtained_at', this.obtained);
    formData.append('expired_at', this.expires);

    this.loading = true;

    this.apiService.post("/me/certificate/upload", formData)
      .then(
        (response) => {
          console.log('Upload successful', response);
          this.dialog.closeAll();

          this.loading = false;
          // Handle success, such as showing a success message
        },
        (error) => {
          console.error('Error uploading file', error);
          // Handle error, such as displaying an error message
        }
      );
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }
}
