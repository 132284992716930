import { Component } from '@angular/core';
import { StorageService } from '../../services/storage.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  constructor(storageService: StorageService, router: Router) {
    storageService.clearAccessToken();
    router.navigate(['/login']);
  }
}
