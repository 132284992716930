<section class="vh-100">
    <div class="container pb-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-12">
                <div class="card" style="border-radius: 1rem;">
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-5 d-none d-md-block">
                            <img src="assets/img/register_bg.webp" alt="login form" class="img-fluid"
                                style="height: 100%; object-fit: cover; object-position: 25%; border-radius: 1rem 0 0 1rem;" />
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">

                                <div class="d-flex align-items-center mb-3 pb-1">
                                    <span class="h1 fw-bold mb-0">
                                        <img src="assets/img/hdgm_e-learning_logo.svg" width="200">
                                    </span>
                                </div>

                                <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Account registreren
                                </h5>

                                <ng-container *ngIf="step == 0">
                                    <div class="row">
                                        <p>Je staat op het punt om je aan te melden voor één van de scholingen van HDGM. Vanaf de volgende pagina word je verder door het inschrijfproces begeleidt.<br><br>De zorgsector is voortdurend in ontwikkeling, wat resulteert in voortdurende aanpassingen van kennis, protocollen en werkwijzen. Mede hierdoor is er binnen HDGM voor gekozen om de scholingen op abonnementsbasis aan te bieden. Tevens biedt een abonnement meer voordelen: 
                                        </p>
                                    </div>

                                    <div class="row">
                                        <ul>
                                            <li><b>Direct opzegbaar:</b> Het abonnement is op elk moment opzegbaar, ook direct na de eerste inschrijving;</li>
                                            <li><b>Leren en herhalen wanneer u wilt:</b> Na het maken van de E-learning kun je deze als naslag blijven gebruiken of nogmaals toetsen zodat je ten alle tijden over een recent certificaat beschikt. Je kunt oneindig veel blijven toetsen/oefenen;</li>
                                            <li><b>Automatische updates:</b> De zorg is continue veranderend. Onze E-learnings worden automatisch ge-update zodat je over de laatst bekende informatie/protocollen beschikt;</li>
                                            <li><b>Gratis Webinars en online scholingen:</b>     HDGM verzorgd online Webinars en scholingen welke gratis te volgen zijn voor abonnementhouders;</li>
                                        </ul>
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button class="btn btn-dark btn-lg btn-block" (click)="step = 1"
                                            type="button">
                                            Begin registratie
                                        </button>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="step == 1">
                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">E-mail *</label>
                                                <input placeholder="E-mail" [(ngModel)]="email" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep1.email}" />
                                                <div class="invalid-feedback">
                                                    Vul een geldig e-mail in
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Aanhef *</label>
                                            <select [(ngModel)]="gender" class="form-select mb-2"
                                                [ngClass]="{'is-invalid': !validationStateStep1.gender}">
                                                <option value="dhr">Dhr.</option>
                                                <option value="mevr">Mevr.</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Vul aanhef in
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Voornaam *</label>
                                                <input placeholder="Voornaam" [(ngModel)]="firstName" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep1.firstName}" />
                                                <div class="invalid-feedback">
                                                    Vul voornaam in
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Achternaam *</label>
                                                <input placeholder="Achternaam" [(ngModel)]="lastName" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep1.lastName}" />
                                                <div class="invalid-feedback">
                                                    Vul achternaam in
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4">
                                            <label class="form-label">Selecteer je niveau *</label>
                                            <select [(ngModel)]="selectedSubscription" class="form-select mb-2"
                                                [ngClass]="{'is-invalid': !validationStateStep1.selectedSubscription}">
                                                <option *ngFor="let subscription of subscriptionOptions"
                                                    [value]="subscription.id">{{subscription.name}} -
                                                    {{toDutchEurFormat(subscription.amount)}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="eventsAvailable()">
                                        <div class="col-md-12 mb-4">
                                            <label class="form-label">Cursus</label>
                                            <select [(ngModel)]="selectedEvent" class="form-select mb-2">
                                                <ng-container *ngFor="let event of eventOptions">
                                                    <option *ngIf="event.can_attend" [value]="event.id">
                                                        {{formatDateToDutch(event.start_at)}} - {{event.content}} -
                                                        {{toDutchEurFormat(event.amount)}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button class="btn btn-dark btn-lg btn-block mr-2" (click)="back()"
                                        type="button">Vorige stap</button>
                                        <button class="btn btn-dark btn-lg btn-block" (click)="verifyStep1()"
                                            type="button">
                                            Volgende stap
                                        </button>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="step == 2">
                                    <div class="row" *ngIf="showOrganisationOption">
                                        <div class="col-md-12 mb-4">
                                            <label class="form-label">Organisatie *</label>
                                            <select [(ngModel)]="selectedOrganisation" class="form-select mb-2"
                                                [ngClass]="{'is-invalid': !validationStateStep2.selectedOrganisation}">
                                                <option value="xx" selected>Ik behoor tot geen organisatie</option>
                                                <option *ngFor="let organisation of organisations"
                                                    [value]="organisation.id">{{organisation.name}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Selecteer een organisatie
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Geboortedatum *</label>
                                                <input placeholder="Jaar" [(ngModel)]="birthdate" type="date"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep2.birthdate}" />
                                                <div class="invalid-feedback">
                                                    Vul geboortedatum in
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Bedrijfsnaam</label>
                                                <input placeholder="Bedrijfsnaam" [(ngModel)]="company" type="text"
                                                    class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">KVK-nummer</label>
                                                <input placeholder="52250644" [(ngModel)]="coc" type="text"
                                                    class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Adres *</label>
                                                <input [(ngModel)]="address" placeholder="Straat + huisnummer"
                                                    type="text" class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep2.address}" />
                                                <div class="invalid-feedback">
                                                    Vul adres in
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Plaats *</label>
                                                <input [(ngModel)]="city" placeholder="Plaats" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep2.city}" />
                                                <div class="invalid-feedback">
                                                    Vul plaats in
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Postcode *</label>
                                                <input [(ngModel)]="zip" placeholder="0000 AB" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep2.zip}" />
                                                <div class="invalid-feedback">
                                                    Vul postcode in
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Telefoon *</label>
                                                <input [(ngModel)]="phone" placeholder="Telefoonnummer" type="text"
                                                    class="form-control form-control-lg"
                                                    [ngClass]="{'is-invalid': !validationStateStep2.phone}" />
                                                <div class="invalid-feedback">
                                                    Vul telefoonnummer in
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button class="btn btn-dark btn-lg btn-block mr-2" (click)="back()"
                                            type="button">Vorige stap</button>
                                        <button class="btn btn-dark btn-lg btn-block" (click)="verifyStep2()"
                                            type="button">Volgende stap</button>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="step == 3">
                                    <div class="row">
                                        <div class="col-md-12 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Wachtwoord</label>
                                                <input [(ngModel)]="password" type="password"
                                                    class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Herhaal wachtwoord</label>
                                                <input [(ngModel)]="passwordRepeat"
                                                    (keyup.enter)="confirmRegistration()" type="password"
                                                    class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 mb-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" [(ngModel)]="acceptTOS"
                                                    id="invalidCheck" required>
                                                <label class="form-check-label" for="invalidCheck">
                                                    Ik ga akkoord met de algemene voorwaarden en geef toestemming voor
                                                    de jaarlijkse automatische incasso voor het HDGM abonnement.
                                                </label>
                                                <div class="invalid-feedback">
                                                    Verplicht veld
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4">
                                            <p style="padding: 20px;
                                            background: #EFEFEF;
                                            border-radius: 7px;">
                                                Na het afronden van de registratie wordt je doorgestuurd naar een
                                                betaalomgeving. De eerste jaarlijkse betaling voor het HDGM abonnement
                                                wordt via iDeal verwerkt. Verlengingen worden via automatische incasso
                                                betaald.
                                                Het iDeal bedrag bestaat uit abonnementskosten en eventueel de kosten
                                                van de praktijkdag.
                                            </p>
                                            <p><strong>Overzicht van je bestelling</strong></p>
                                            <p class="mb-0" *ngIf="selectedEvent != ''">
                                                Totaal <b>{{getInclVATPrice()}}</b>
                                                <ng-container *ngIf="getTotalFlat() != 0">
                                                    <br>
                                                    Verlengkosten (jaarlijks) <b>{{getSubscriptionPrice()}}</b>
                                                </ng-container>
                                            </p>
                                            <p class="mb-0" *ngIf="selectedEvent == ''">
                                                Abonnement (jaarlijks) <b>{{getSubscriptionPrice()}}</b><br />
                                            </p>
                                        </div>
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button class="btn btn-dark btn-lg btn-block mr-2" (click)="back()"
                                            type="button">Vorige
                                            stap</button>
                                        <button class="btn btn-dark btn-lg btn-block" (click)="confirmRegistration()"
                                            [disabled]="acceptTOS == false || password !== passwordRepeat"
                                            type="button">Registratie afronden</button>
                                    </div>
                                </ng-container>

                                <p class="mb-5 pb-lg-2" style="color: #000000;">Heb je al een account? <b><a
                                            [href]="['/login']" style="color: #b20029;">Inloggen</a></b></p>
                                <a href="https://hdgm.nl/algemene-voorwaarden" target="_blank"
                                    class="small text-muted">Gebruiksvoorwaarden, </a>
                                <a href="https://hdgm.nl/privacy-verklaring" target="_blank"
                                    class="small text-muted">Privacybeleid</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="alert alert-danger" role="alert" [ngClass]="{'showError': showError}">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
        viewBox="0 0 16 16">
        <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg> {{errorMessage}}
</div>