import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../../../services/api.service';
import { StorageService } from '../../../services/storage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  hash: any;
  item: any;

  loading: boolean = true;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private storageService: StorageService, private router: Router) {
    let params = this.route.snapshot.queryParams;
    this.hash = this.route.snapshot.queryParams['hash'];
    let self = this;
    setTimeout(function () { self.fetchItem(); }, 5000);
  }

  fetchItem ()
  {
    let self = this;
    this.apiService.get("/payments/status/" + this.hash).then((data) => {
      self.loading = false;
      self.item = data;
      if (self.item['paid'] === true)
      {
        setTimeout(function () { location.href = './login' }, 1000);
      }
    });
  }
}
