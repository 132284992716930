import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';

import { ApiService } from './services/api.service';
import { environment } from '../environments/environment';


import {
  MatDialog
} from '@angular/material/dialog';
import { InactiveBlockComponent } from './components/inactive-block/inactive-block.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'E-learning';
  loading = true;
  isDevelopmentEnvironment = false;

  constructor(private router: Router, private _location: Location, private apiService: ApiService, private dialog: MatDialog) {
    setTimeout(() => {
      this.loading = false;

      this.shouldBlockUser();
    }, 850);

    if (environment.isDevelopment) {
      this.isDevelopmentEnvironment = true;
    }
  }

  shouldBlockUser() {
    if (this.router.url.includes("/module") || this.router.url.includes("/lesson")) {
      this.apiService.get("/me").then((data: any) => {
        if (data.active == 0) {
          let dialogRef = this.dialog.open(InactiveBlockComponent, {
          });

          dialogRef.afterClosed().subscribe((data) => {
            window.location.href = "/";
          });
        }
      });
    }
  }

  showNav() {
    const hiddenUrls = ['/login', '/register', '/forgot-password', '/payment/return', '/password-recovery'];

    const currentUrl = this.router.url.split('?')[0];

    // Check if any of the hiddenUrls are contained within the currentUrl
    const isHidden = hiddenUrls.some(hiddenUrl => currentUrl.includes(hiddenUrl));

    return !isHidden;
  }

  showBackButton() {
    if (this.router.url !== "/") {
      return true;
    } else {
      return false;
    }
  }

  goBack() {
    this._location.back();
  }
}
