import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';

import {
  MatDialog
} from '@angular/material/dialog';

import { ProgressCircleComponent } from '../../components/progress-circle/progress-circle.component';
import { EventDialogComponent } from '../../components/event-dialog/event-dialog.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, ProgressCircleComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  profile: any = [];
  subscriptions: any = [];
  certificates: any = [];
  modules: any = [];

  userEvents: any = [];
  events: any = [];

  constructor(
    public apiService: ApiService,
    public dialog: MatDialog
  ) {
    let self = this;

    apiService.get('/me').then((response) => {
      self.profile = response;
    });

    apiService.get('/me/subscriptions').then((response) => {
      self.subscriptions = response;
    });

    apiService.get('/me/certificates').then((response) => {
      self.certificates = response;
    });

    apiService.get('/me/modules').then((response) => {
      self.modules = response;
    });

    apiService.get('/me/events/attend').then((response) => {
      self.userEvents = response;
    });

    apiService.get('/me/events').then((response) => {
      self.events = response;
    });
  }

  transformDateTime(dateTime: any) {
    if (dateTime == null) {
      return "N/A";
    } else {
      // Split the date and time parts
      let [date, time] = dateTime.split(' ');

      // Split the date part into year, month, and day
      let [year, month, day] = date.split('-');

      // Return the transformed date in DD-MM-YYYY format
      return `${day}-${month}-${year}`;
    }
  }

  getDayFromDateString(dateString: any) {
    // Split the dateString by the space to separate date and time
    let datePart = dateString.split(' ')[0];

    // Split the date part by the dash to get the individual components
    let dateComponents = datePart.split('-');

    // The day number is the third component
    let dayNumber = dateComponents[2];

    // Convert the day number to an integer and return it
    return parseInt(dayNumber, 10);
  }

  getMonthFromDateString(dateString: any) {
    // Define an array with Dutch month names
    const dutchMonths = [
      "januari", "februari", "maart", "april", "mei", "juni",
      "juli", "augustus", "september", "oktober", "november", "december"
    ];

    // Split the dateString by the space to separate date and time
    let datePart = dateString.split(' ')[0];

    // Split the date part by the dash to get the individual components
    let dateComponents = datePart.split('-');

    // The month number is the second component, convert it to an integer (subtract 1 for zero-based index)
    let monthNumber = parseInt(dateComponents[1], 10) - 1;

    // Retrieve the Dutch month name from the array
    let monthName = dutchMonths[monthNumber];

    return monthName;
  }

  openEvent(event: any, isAttending: any) {
    const self = this;
    event.isAttending = isAttending;

    let dialogRef = this.dialog.open(EventDialogComponent, {
      data: event
    });

    dialogRef.afterClosed().subscribe(result => {
      self.apiService.get('/me/events/attend').then((response: any) => {
        self.userEvents = response;
      });

      self.apiService.get('/me/events').then((response: any) => {
        self.events = response;
      });
    });
  }
}
