import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ModuleComponent } from './pages/module/module.component';
import { ModuleDetailsComponent } from './pages/module/details/details.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { QuestionComponent } from './pages/module/question/question.component';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { LogoutComponent } from './pages/logout/logout.component';

import { ProfileComponent } from './pages/profile/profile.component';
import { ModulesComponent } from './pages/modules/modules.component';
import { authGuardGuard } from './auth-guard.guard';

import { ModulePreviewComponent } from './pages/module-preview/module-preview.component';
import { LessonPreviewComponent } from './pages/module-preview/lesson-preview/lesson-preview.component';
import { PaymentComponent } from './pages/register/payment/payment.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'password-recovery/:hash',
        component: PasswordRecoveryComponent
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'module/:id',
        component: ModuleComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'module-preview/:id',
        component: ModulePreviewComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'module-preview/lesson/:id',
        component: LessonPreviewComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'modules',
        component: ModulesComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'lesson/:id',
        component: ModuleDetailsComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'module/:id/question',
        component: QuestionComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'certificates',
        component: CertificatesComponent,
        canActivate: [authGuardGuard]
    },
    {
        path: 'payment/return',
        component: PaymentComponent
    },
    {
        path: 'payment/return-event',
        component: PaymentComponent
    },
];
