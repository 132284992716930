import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatDialog
} from '@angular/material/dialog';

import { ApiService } from '../../services/api.service';
import { ProfileDialogComponent } from '../../components/profile-dialog/profile-dialog.component';
import { UpdatePasswordDialogComponent } from '../../components/update-password-dialog/update-password-dialog.component';
import { SubscriptionUpgradeDialogComponent } from '../../components/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { environment } from '../../../environments/environment';
import { NewSubscriptionComponent } from '../../components/new-subscription/new-subscription.component';
import { CancelSubscriptionComponent } from '../../components/cancel-subscription/cancel-subscription.component';


@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  profile: any = [];
  subscriptions: any = [];
  invoices: any = [];
  apiBaseURL: any;

  constructor(private apiService: ApiService, public dialog: MatDialog) {
  }

  openEditDialog(): void {
    let dialogRef = this.dialog.open(ProfileDialogComponent, {
      data: this.profile
    });
  }

  updatePassword(): void {
    let dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      data: this.profile
    });
  }

  upgradeSubscription(subscription: any): void {
    let dialogRef = this.dialog.open(SubscriptionUpgradeDialogComponent, {
      data: subscription
    });
  }

  cancelSubscription(subscription: any): void {
    let dialogRef = this.dialog.open(CancelSubscriptionComponent, {
      data: subscription
    }).afterClosed().subscribe(result => {
      this.getData();
    });
  }

  newSubscription(): void {
    let dialogRef = this.dialog.open(NewSubscriptionComponent, {
    }).afterClosed().subscribe(result => {
      this.getData();
    });
  }

  getData() {
    this.apiService.get('/me').then((response) => {
      this.profile = response;
    });

    this.apiService.get('/me/subscriptions').then((response) => {
      this.subscriptions = response;
    });

    this.apiService.get('/me/invoices').then((response) => {
      this.invoices = response;
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  formatDateTime(dateTimeStr: any) {
    if (dateTimeStr !== null) {
      // Split the date and time parts
      const [datePart, timePart] = dateTimeStr.split(' ');

      // Split the date into year, month, and day
      const [year, month, day] = datePart.split('-');

      // Return the formatted date
      return `${day}-${month}-${year}`;
    } else {
      return "?";
    }
  }

  download(invoice: any) {
    location.href = environment.api_url + '/me/invoices/' + invoice.id + '/download';
  }

}
