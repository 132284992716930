import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Location } from '@angular/common';

import { ProgressCircleComponent } from '../../../components/progress-circle/progress-circle.component';
import { ImagePopoverComponent } from '../../../components/image-popover/image-popover.component';

@Component({
  selector: 'app-lesson-preview',
  standalone: true,
  imports: [CommonModule, ProgressCircleComponent],
  templateUrl: './lesson-preview.component.html',
  styleUrls: ['./lesson-preview.component.scss']
})
export class LessonPreviewComponent {

  module_id: any;
  lesson_id: any;

  lesson: any = [];
  lessons: any = [];
  module: any = {};

  lessonHierarchy: any = [];

  constructor(private _location: Location, private apiService: ApiService, private route: ActivatedRoute, public dialog: MatDialog) {
    this.lesson_id = this.route.snapshot.paramMap.get('id');
    this.setupLesson();
  }

  setupLesson() {
    //get current lesson data
    this.apiService.get("/lessons/" + this.lesson_id).then((data) => {
      this.lesson = data;
      this.module_id = this.lesson.module_id;

      this.setupImagePopovers();

      this.getLessons();
      this.getModule();
    });
  }

  openLesson(id: string) {
    this._location.go('/module-preview/lesson/' + id);
    this.lesson_id = id;
    this.setupLesson();
  }

  setupImagePopovers() {
    setTimeout(() => {
      let self = this;
      const images: any = document.getElementsByTagName("img");

      for (let i of images) {
        i.parentElement.removeAttribute("href");

        i.addEventListener('click', function () {
          let dialogRef = self.dialog.open(ImagePopoverComponent, {
            data: i.getAttribute("src")
          }).afterClosed().subscribe(result => {
          });
        });
      }
    }, 500);
  }

  getModule() {
    this.apiService.get("/modules/" + this.module_id).then((data: any) => {
      this.module = data;
    })
  }

  getLessons() {
    this.apiService.get("/modules/" + this.module_id + "/lessons").then((data: any) => {
      this.lessons = data;

      this.lessonHierarchy = this.buildLessonHierarchy(data);
    })
  }

  canShowNextButton() {
    const index = this.lessons.findIndex((obj: any) => obj.id === this.lesson_id);

    if ((index + 1) !== this.lessons.length) {
      return true;
    } else {
      return false;
    }
  }

  canShowPreviousButton() {
    if(this.lessons[0] !== undefined) {
      if (this.lesson_id !== this.lessonHierarchy[0].id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  goNextLesson() {
    const self = this;

    const lesson = this.lessonHierarchy.find((item: any) => item.id == this.lesson_id);
    if (lesson == undefined) {
      //current lesson is child
      const parent = this.lessonHierarchy.find((item: any) => item.id == this.lesson.parent_id)
      //loop through parent.children, if length is more than index of cur selected child go to next root item
      const childrenList = parent.children;

      const indexOfChild = childrenList.findIndex((item: any) => item.id == this.lesson.id);
      const nextLessonIndex = indexOfChild + 1;

      const nextLesson = childrenList[nextLessonIndex];
      if (nextLesson == undefined) {
        //go to next root level lesson
        const currentLessonIndex = this.lessonHierarchy.findIndex((item: any) => item.id == parent.id);
        const nextLessonIndex = currentLessonIndex + 1;

        const nextLesson = this.lessonHierarchy[nextLessonIndex];
        if (nextLesson !== undefined) {
          this.openLesson(nextLesson.id);
        }

      } else {
        this.openLesson(nextLesson.id);
      }
    } else {
      //current lesson is root

      //check if lesson has children
      if (lesson.hasChildren) {
        this.openLesson(lesson.children[0].id);
      } else {
        const currentLessonIndex = self.lessonHierarchy.findIndex((item: any) => item.id == self.lesson.id);
        const nextLessonIndex = currentLessonIndex + 1;

        const nextLesson = self.lessonHierarchy[nextLessonIndex];
        if (nextLesson !== undefined) {
          self.openLesson(nextLesson.id);
        }
      }
    }
  }

  goPreviousLesson() {
    const self = this;

    const lesson = this.lessonHierarchy.find((item: any) => item.id == this.lesson_id);
    if (lesson == undefined) {
      //current lesson is child
      const parent = this.lessonHierarchy.find((item: any) => item.id == this.lesson.parent_id)
      //loop through parent.children, if length is more than index of cur selected child go to next root item
      const childrenList = parent.children;

      const indexOfChild = childrenList.findIndex((item: any) => item.id == this.lesson.id);
      const nextLessonIndex = indexOfChild - 1;

      const nextLesson = childrenList[nextLessonIndex];
      if (nextLesson == undefined) {
        //go to next root level lesson
        const currentLessonIndex = this.lessonHierarchy.findIndex((item: any) => item.id == parent.id);
        const nextLessonIndex = currentLessonIndex - 1;

        const nextLesson = this.lessonHierarchy[nextLessonIndex];
        if (nextLesson !== undefined) {
          this.openLesson(nextLesson.id);
        } else {
          this.openLesson(parent.id);
        }

      } else {
        this.openLesson(nextLesson.id);
      }
    } else {
      //current lesson is root
      const currentLessonIndex = this.lessonHierarchy.findIndex((item: any) => item.id == this.lesson_id);
      const previousLesson = this.lessonHierarchy[currentLessonIndex - 1];

      if (previousLesson != undefined) {
        if (previousLesson.hasChildren) {
          const lastChildrenLesson = previousLesson.children[previousLesson.children.length - 1];
          self.openLesson(lastChildrenLesson.id)
        } else {
          self.openLesson(previousLesson.id);
        }
      }
    }
  }

  buildLessonHierarchy(lessons: any[]): any[] {
    let map = new Map();
    let roots: any[] = [];

    lessons.forEach(lesson => {
      map.set(lesson.id, { ...lesson, children: [] });
    });

    lessons.forEach(lesson => {
      if (lesson.parent_id !== null) {
        let parent = map.get(lesson.parent_id);
        if (parent) {

          if (parent.id == this.lesson.parent_id || lesson.parent_id == this.lesson.id) {
            parent.collapse = true;
          } else {
            parent.collapse = false;
          }
          parent.hasChildren = true;
          parent.children.push(map.get(lesson.id));
        }
      } else {
        lesson.hasChildren = false;
        roots.push(map.get(lesson.id));
      }
    });

    return roots;
  }
}