<div class="screenWide">
    <h2 mat-dialog-title>Evenement details</h2>
    <div>
        <button mat-button mat-dialog-close>Sluiten</button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <table style="min-width: 50vw;">
        <tr>
            <td>
                Onderwerp:
            </td>
            <td>{{data.title}}</td>
        </tr>
        <tr>
            <td>
                Datum:
            </td>
            <td>{{extractDate(data.start_at)}}</td>
        </tr>
        <tr>
            <td>
                Begintijd:
            </td>
            <td>{{extractTime(data.start_at)}}</td>
        </tr>
        <tr>
            <td>
                Eindtijd:
            </td>
            <td>{{extractTime(data.end_at)}}</td>
        </tr>
        <tr>
            <td>
                Scholing:
            </td>
            <td>{{data.location}}</td>
        </tr>
    </table>

    <hr>

    <h2>{{data.description}}</h2>
    <div class="row">
        <div class="col-md-12 mb-4">
            {{data.content}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div *ngIf="showError" style="padding:10px;text-align:center; color: red;">
        {{errorMessage}}
    </div>
    <div data-mdb-input-init class="form-outline mr-2" *ngIf="data.isAttending == false">
        <input [(ngModel)]="data.comment" placeholder="Opmerking" type="text" class="form-control form-control-lg" />
    </div>
    <ng-container *ngIf="data.isAttending == false">
        <button (click)="attend()" class="attendButton mr-2" mat-button>Inschrijven</button>
    </ng-container>
</mat-dialog-actions>