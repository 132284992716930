import { Component } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ProgressCircleComponent } from '../../components/progress-circle/progress-circle.component';

@Component({
  selector: 'app-module-preview',
  standalone: true,
  imports: [CommonModule, FormsModule, ProgressCircleComponent],
  templateUrl: './module-preview.component.html',
  styleUrl: './module-preview.component.scss'
})
export class ModulePreviewComponent {
  module_id: any;

  module: any = [];
  lessons: any = [];

  lessonHierarchy: any = [];

  showError = false;
  errorMessage = "";

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    this.module_id = this.route.snapshot.paramMap.get('id');

    this.apiService.get("/modules/" + this.module_id).then((data) => {
      this.module = data;
    });

    this.apiService.get("/modules/" + this.module_id + "/lessons").then((data: any) => {
      this.lessons = data;

      this.lessonHierarchy = this.buildLessonHierarchy(data);
      console.log(this.lessonHierarchy)
    });
  }

  activateModule(id: any) {
    this.apiService.post("/me/module/start/" + id, {}).then((data: any) => {
      window.location.href = "/module/" + id;
    }).catch((err) => {
      this.errorMessage = err.error.message;
      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 3000);
    });
  }

  buildLessonHierarchy(lessons: any[]): any[] {
    let map = new Map();
    let roots: any[] = [];

    lessons.forEach(lesson => {
      map.set(lesson.id, { ...lesson, children: [] });
    });

    lessons.forEach(lesson => {
      if (lesson.parent_id !== null) {
        let parent = map.get(lesson.parent_id);
        if (parent) {
          parent.collapse = false;
          parent.hasChildren = true;
          parent.children.push(map.get(lesson.id));
        }
      } else {
        lesson.hasChildren = false;
        roots.push(map.get(lesson.id));
      }
    });

    return roots;
  }
}