import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ProgressCircleComponent } from '../../../components/progress-circle/progress-circle.component';

import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [CommonModule, ProgressCircleComponent],
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss'
})
export class QuestionComponent implements OnInit {

  module_id: any;
  question: any = {};
  module: any = {};
  lesson: any = {};
  score: any = 0;

  hasBeenAnswered = false;
  isCorrect = false;
  isFinished = false;

  correctAnswerId = "";
  answerId = "";
  description = '';

  constructor(private apiService: ApiService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
    this.module_id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    let self = this;
    this.apiService.get("/me/module/" + this.module_id).then((data) => {
      this.module = data;
      self.score = this.module['score'];
    })

    this.getQuestion();
  }

  getQuestion() {
    this.hasBeenAnswered = false;
    this.isCorrect = false;
    this.correctAnswerId = "";

    this.apiService.get("/me/module/" + this.module_id + "/question").then((data: any) => {
      this.question = data;

      if(data['module'] !== undefined) {
        this.isFinished = true;
      }
    });
  }

  selectAnswer(answer: any) {
    if (this.hasBeenAnswered == false) {
      const answerId = answer.id;

      let self = this;

      this.apiService.post("/me/question/" + this.question.id + "/answer", { userAnswerId: answerId }).then((data: any) => {
        self.hasBeenAnswered = true;
        self.isCorrect = data['is_correct'];
        self.score = data['score'];
        self.answerId = answerId;
        self.correctAnswerId = data['correct_answer_id'];
        
        self.description = data['description'];
        self.setLesson(data['user_lesson_id']);

        self.module.score = data['score'];
      });
    }
  }

  setLesson (lessonId: any) {
    if (typeof(lessonId) !== 'string')
    {
      this.lesson = {};
      return;
    }
    this.apiService.get("/me/lesson/" + lessonId).then((data) => {
      this.lesson = data;
  });
  }

  indexToLetter(i: number) {
    const letters = ['A', 'B', 'C'];
    return letters[i];
  }

  nextQuestion () {
    this.getQuestion();
  }
}
