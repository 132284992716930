import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {

  public organisationId: any;
  public selectedEventId: any;
  public selectedSubscriptionId: any;

  step = 0;
  email = "";

  firstName = "";
  lastName = "";

  gender = ""

  address = "";
  city = "";
  zip = "";
  phone = "";

  company = "";
  coc = "";

  birthdate = "";

  loading = false;

  password = "";
  passwordRepeat = "";

  errorMessage = "";
  showError = false;

  acceptTOS = false;

  showOrganisationOption = true;

  organisations: any = [];
  selectedOrganisation: any;

  subscriptionOptions: any = [];
  selectedSubscription: any;

  eventOptions: any = [];
  selectedEvent: any;

  validationStateStep1 = {
    email: true,
    gender: true,
    firstName: true,
    lastName: true,
    selectedSubscription: true
  };

  validationStateStep2 = {
    selectedOrganisation: true,
    birthdate: true,
    address: true,
    city: true,
    zip: true,
    phone: true
  };

  constructor(private route: ActivatedRoute, private apiService: ApiService, private storageService: StorageService, private router: Router) {
    let params = this.route.snapshot.queryParams;
    this.organisationId = this.route.snapshot.queryParams['id'];
    this.selectedEventId = this.route.snapshot.queryParams['eventId'];
    this.selectedSubscriptionId = this.route.snapshot.queryParams['subscriptionId'];

    if (!this.selectedSubscriptionId)
    {
      this.selectedSubscriptionId = '';
    }
    if (!this.selectedEventId)
    {
      this.selectedEventId = '';
    }
    else {
      this.selectedEvent = this.selectedEventId;
    }
    if (!this.selectedSubscriptionId)
    {
      this.selectedSubscriptionId = '';
    }
    else
    {
      this.selectedSubscription = this.selectedSubscriptionId;
    }
    if (!this.organisationId)
    {
      this.organisationId = '';
    }
    else
    {
      this.selectedOrganisation = this.organisationId;
    }

    this.apiService.get("/register/organisations?activeId=" + this.organisationId).then((data) => {
      this.organisations = data;
    });
    this.apiService.get("/register/subscriptions?activeId=" + this.selectedSubscriptionId + '&organisationId=' + this.organisationId).then((data) => {
      this.subscriptionOptions = data;
      this.selectedSubscription = this.subscriptionOptions[0].id;
    });
    this.apiService.get("/register/events?activeId=" + this.selectedEventId + '&organisationId=' + this.organisationId).then((data) => {
      this.eventOptions = data;
    });
  }

  validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex and return the result
    return emailRegex.test(this.email);
  }

  formatDateToDutch(dateString: any) {
    const date = new Date(dateString.replace(" ", "T"));
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }

    const options: any = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    const formatter = new Intl.DateTimeFormat('nl-NL', options);
    return formatter.format(date);
  }

  validateFormStep1() {
    this.validationStateStep1.email = !!this.email && this.validateEmail();
    this.validationStateStep1.gender = !!this.gender;
    this.validationStateStep1.firstName = !!this.firstName;
    this.validationStateStep1.lastName = !!this.lastName;
    this.validationStateStep1.selectedSubscription = !!this.selectedSubscription;

    return Object.values(this.validationStateStep1).every(value => value === true);
  }

  validateFormStep2() {
    this.validationStateStep2.selectedOrganisation = !!this.selectedOrganisation;
    this.validationStateStep2.birthdate = !!this.birthdate;
    this.validationStateStep2.address = !!this.address;
    this.validationStateStep2.city = !!this.city;
    this.validationStateStep2.zip = !!this.zip;
    this.validationStateStep2.phone = !!this.phone;

    return Object.values(this.validationStateStep2).every(value => value === true);
  }

  verifyStep1() {
    //check if account with email already exists, if not continue to step 2

    if (this.validateFormStep1()) {
      this.step = 2;
    }
  }

  verifyStep2() {
    //check if account with email already exists, if not continue to step 2

    if (this.validateFormStep2()) {
      this.step = 3;
    }
  }

  toDutchEurFormat(number: any) {
    return new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }

  getExclVATPrice() {
    let price = 0;
    this.subscriptionOptions.forEach((subscription: any) => {
      if (subscription.id == this.selectedSubscription) {
        price += subscription.amount;
      }
    });

    return this.toDutchEurFormat((price / 121 * 100));
  }

  eventsAvailable() {
    let available = false;
    this.eventOptions.forEach((event: any) => {
      if (event.can_attend) {
        available = true;
      }
    });

    return available;
  }

  getEventPrice ()
  {
    let price = 0;
    this.eventOptions.forEach((event: any) => {
      if (event.id == this.selectedEvent) {
        price += event.amount;
      }
    });

    return this.toDutchEurFormat(price);
  }

  getSubscriptionPrice ()
  {
    let price = 0;
    this.subscriptionOptions.forEach((subscription: any) => {
      if (subscription.id == this.selectedSubscription) {
        price += subscription.amount;
      }
    });

    return this.toDutchEurFormat(price);
  }

  getInclVATPrice() {
    let price = 0;
    this.subscriptionOptions.forEach((subscription: any) => {
      if (subscription.id == this.selectedSubscription) {
        price += parseFloat(subscription.amount);
      }
    });
    this.eventOptions.forEach((event: any) => {
      if (event.id == this.selectedEvent) {
        price += parseFloat(event.amount);
      }
    });

    return this.toDutchEurFormat(price);
  }

  getTotalFlat() {
    let price = 0;
    this.subscriptionOptions.forEach((subscription: any) => {
      if (subscription.id == this.selectedSubscription) {
        price += subscription.amount;
      }
    });
    this.eventOptions.forEach((event: any) => {
      if (event.id == this.selectedEvent) {
        price += event.amount;
      }
    });

    return price;
  }

  back() {
    this.step--;
  }

  validateName() {
    if (this.firstName.length > 3 && this.lastName.length > 3) {
      this.step = 2;
    }
  }

  setOrganisation() {
    this.step = 3;
  }

  skipCode() {
    this.selectedOrganisation = "xx";
    this.step = 3;
  }

  displayError(message: string) {
    this.errorMessage = message;
    this.showError = true;
    setTimeout(() => {
      this.showError = false;
    }, 3000);
  }

  confirmRegistration() {
    this.apiService.post("/register", {
      email: this.email,
      password: this.password,
      first_name: this.firstName,
      last_name: this.lastName,
      organisation_id: this.selectedOrganisation,
      study_level_id: "xx",
      address: this.address,
      zipcode: this.zip,
      country: 'Nederland',
      phone: this.phone,
      city: this.city,
      gender: this.gender,
      company: this.company,
      coc: this.coc,
      birthdate: this.birthdate,
      subscription_id: this.selectedSubscription,
      event_id: this.selectedEvent
    }).then((response: any) => {
      if(response.message) {
        window.open("/", "_self");
      }
      window.open(response.redirectUrl, "_self");
    }).catch((err) => {
      this.displayError("Er is iets mis gegaan. Probeer het later nog een keer, of neem contact op met HDGM.")
    });
  }
}