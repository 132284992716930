<section class="vh-100">
    <div class="container pb-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
                <div class="card" style="border-radius: 1rem;">
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-5 d-none d-md-block">
                            <img src="assets/img/login_bg.webp" alt="login form" class="img-fluid"
                                style="height: 100%; object-fit: cover; object-position: left; border-radius: 1rem 0 0 1rem;" />
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">

                                <div class="d-flex align-items-center mb-3 pb-1">
                                    <span class="h1 fw-bold mb-0">
                                        <img src="assets/img/hdgm_e-learning_logo.svg" width="200">
                                    </span>
                                </div>

                                <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Wachtwoord vergeten</h5>

                                <ng-container *ngIf="step == 0">
                                    <div data-mdb-input-init class="form-outline mb-4">
                                        <label class="form-label">Email</label>
                                        <input [(ngModel)]="email" (keyup.enter)="reset()" type="email"
                                            class="form-control form-control-lg" />
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button (click)="reset()" [disabled]="!isValidEmail(email) || loading"
                                            class="btn btn-dark btn-lg btn-block" type="button">Wachtwoord resetten</button>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="step == 1">
                                    <div class="alert alert-warning" role="alert">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                            <path
                                                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg> Er is een reset URL verzonden naar <b>{{email}}</b>
                                    </div>

                                    <!-- <div class="row">
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Nieuw wachtwoord</label>
                                                <input #passwordInput [(ngModel)]="password" type="password"
                                                    class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <div data-mdb-input-init class="form-outline">
                                                <label class="form-label">Herhaal wachtwoord</label>
                                                <input [(ngModel)]="passwordRepeat" (keyup.enter)="setPassword()"
                                                    type="password" class="form-control form-control-lg" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pt-1 mb-4">
                                        <button class="btn btn-dark btn-lg btn-block" (click)="validateCode()"
                                            [disabled]="code.length < 6 || loading || password.length < 4 || password != passwordRepeat"
                                            type="button">Wachtwoord instellen</button>
                                    </div> -->
                                </ng-container>

                                <p class="mb-5 pb-lg-2" style="color: #000000;">Herinner je wel je wachtwoord? <b><a
                                            [href]="['/login']" style="color: #b20029;">Inloggen</a></b></p>
                                <a href="#!" class="small text-muted">Gebruiksvoorwaarden</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="alert alert-danger popup" role="alert" [ngClass]="{'showError': showError}">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
        viewBox="0 0 16 16">
        <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg> {{errorMessage}}
</div>