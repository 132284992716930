import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  standalone: true,
  imports: [],
  templateUrl: './progress-circle.component.html',
  styleUrl: './progress-circle.component.scss'
})
export class ProgressCircleComponent implements OnChanges {
  @Input() score = 0;
  @Input() color = "#39b54a";

  startValue = 0;

  timeout = false;

  @ViewChild('circularProgress') circularProgress: HTMLElement | any;
  @ViewChild('percentage') progressValue: HTMLElement | any;
  @ViewChild('innerCircle') innerCircle: HTMLElement | any;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateScore();

    if(this.score < 35) {
      this.color = "#ffe752";
    } else if(this.score >= 35 && this.score < 70) {
      this.color = "#eca602";
    } else {
      this.color = "#39b54a";
    }
  }

  updateScore() {
    if (this.timeout == false) {
      this.timeout = true;
      setTimeout(() => {
        let endValue = Number(this.circularProgress.nativeElement.getAttribute("data-percentage")),
          speed = 10,
          progressColor = this.circularProgress.nativeElement.getAttribute("data-progress-color");

        if (this.startValue != endValue) {
          const progress = setInterval(() => {
            this.startValue++;
            this.progressValue.nativeElement.textContent = `${this.startValue}%`;

            this.innerCircle.nativeElement.style.backgroundColor = `${this.circularProgress.nativeElement.getAttribute(
              "data-inner-circle-color"
            )}`;

            this.circularProgress.nativeElement.style.background = `conic-gradient(${progressColor} ${this.startValue * 3.6
              }deg,${this.circularProgress.nativeElement.getAttribute("data-bg-color")} 0deg)`;

            if (this.startValue === endValue) {
              clearInterval(progress);
            }
          }, speed);
        }

        this.timeout = false;
      }, 500);
    }
  }
}