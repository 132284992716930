<section class="modules mt-2 mt-lg-5 mb-8 px-3 px-md-0">
    <div class="container breadcrumb">
        <div class="row">
            <div class="col-12">
                Dashboard &rsaquo; Modules
            </div>
        </div>
    </div>
    <div class="container border border-white bg_white box_shadow_2 border_radius_std mb-5">
        <div class="row">
            <div class="col-12 d-flex align-items-center mb-4">
                <div class="title_icon_leftTop"><span class="material-symbols-outlined">school</span></div>
                <h2 class="ms-3 mt-2">Actieve modules</h2>
            </div>
        </div>


        <div class="row horizontal_line_white_desktop mx-lg-4 mx-2">
            <div class="col-lg-4 ps-lg-3" *ngFor="let module of activeModules">
                <h3 class="mb-2">{{module.title}}</h3>
                <div class="d-flex mb-4">
                    <app-progress-circle [score]="module.score"></app-progress-circle>
                    <div class="ms-3 ms-lg-4 mt-4">
                        <a *ngIf="module.status !== 'FINISHED'" class="d-block"
                            [href]="['/module/' + module.id + '/question']">&raquo;
                            Toetsing hervatten</a>

                        <a *ngIf="module.status == 'FINISHED'" class="d-block" [href]="['/module/' + module.id]">&raquo;
                            Toetsing afgerond</a>
                        <a *ngIf="module.status !== 'FINISHED'" class="d-block"
                            [href]="['/module/' + module.id]">&raquo; Theorie
                            lezen</a>
                        <a *ngIf="module.status == 'FINISHED'" class="d-block" [href]="['/certificates']">&raquo;
                            Certificaat behaald</a>

                        <a *ngIf="module.status == 'FINISHED'" class="d-block" [href]="['/module/' + module.id]">&raquo;
                            Opnieuw toetsen</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container border border-light bg_lightgrey_std box_shadow_2 border_radius_std mb-5">
        <div class="row horizontal_line_white_std">
            <div class="col-lg-12">
                <div class="d-flex align-items-center mb-3">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">search</span></div>
                    <h2 class="ms-3 mt-2">Nieuwe modules zoeken</h2>
                </div>
            </div>
            <div class="col-lg-12 px-lg-5 px-4">
                <div class="input-group mb-4">
                    <input type="text" class="form-control" placeholder="Ik zoek..." aria-label="Module naam"
                        aria-describedby="button-addon2" [(ngModel)]="search">
                    <!-- <button class="btn btn-secondary" type="button" id="button-addon2">Zoeken</button> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex align-items-center mb-4">
                <div class="title_icon_float"><span class="material-symbols-outlined">school</span></div>
                <h2 class="ms-3 mt-2">Populaire modules</h2>
            </div>

            <ng-container *ngFor="let module of modules">
                <div *ngIf="canDisplaySearch(module.title)" class="col-lg-4 pb-4 px-4 px-lg-5">
                    <h3 class="mb-2">{{module.title}}</h3>
                    <div [innerHTML]="module.intro"></div>
                    <div>
                        <a class="btn btn-light mb-3 mr-2" [href]="['/module-preview/' + module.id]">Meer info</a>
                        <a class="btn btn-secondary mb-3" (click)="activateModule(module.id)">Module
                            activeren</a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<div class="alert alert-danger" role="alert" [ngClass]="{'showError': showError}">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
        viewBox="0 0 16 16">
        <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg> {{errorMessage}}
</div>