<h2 mat-dialog-title>Persoonsgegevens</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-4 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Voornaam</label>
                <input [(ngModel)]="data.first_name" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Achternaam</label>
                <input [(ngModel)]="data.last_name" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">E-mail</label>
                <input [(ngModel)]="data.email" type="text" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Postcode</label>
                <input [(ngModel)]="data.zipcode" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-3">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Adres</label>
                <input [(ngModel)]="data.address" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-3">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Stad</label>
                <input [(ngModel)]="data.city" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-3 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Land</label>
                <input [(ngModel)]="data.country" type="text" class="form-control form-control-lg" />
            </div>
        </div>
    </div>

    <hr>

    <h2>Bedrijfsgegevens</h2>
    <div class="row">
        <div class="col-md-4 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">COC</label>
                <input [(ngModel)]="data.coc" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">BTW-nummer</label>
                <input [(ngModel)]="data.vat_number" type="text" class="form-control form-control-lg" />
            </div>
        </div>
        <div class="col-md-4">
            <div data-mdb-input-init class="form-outline">
                <label class="form-label">Bedrijfsnaam</label>
                <input [(ngModel)]="data.company" type="text" class="form-control form-control-lg" />
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuleren</button>
    <button mat-button [mat-dialog-close]="true" (click)="saveChanges()">Opslaan</button>
</mat-dialog-actions>