<h2 mat-dialog-title>Abonnement upgraden</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Curabitur egestas luctus libero, ac vulputate
                nisl eleifend volutpat.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mb-4">
            <ng-container *ngFor="let subscription of subscriptions">
                <div>
                    <h2 class="mb-0"> <input type="checkbox"> {{subscription.name}}</h2>
                    <p>€ {{subscription.amount}}</p>
                </div>
            </ng-container>
        </div>
    </div>

    <hr>
    <div class="flex">
        <button mat-button [mat-dialog-close]="true">Annuleren</button>
        <button (click)="save()" [disabled]="passwordRepeat !== password || password == ''" mat-stroked-button
            [mat-dialog-close]="true">Selecteren</button>
    </div>
</mat-dialog-content>