import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  email = "";

  loading = false;

  step = 0;
  code = [];

  showError = false;
  errorMessage = "";

  password = "";
  passwordRepeat = "";

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router) {

  }


  displayError(message: string) {
    this.errorMessage = message;
    this.showError = true;
    setTimeout(() => {
      this.showError = false;
    }, 3000);
  }

  isValidEmail(email: string) {
    // Regular expression to match email pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex and return the result
    return emailRegex.test(email);
  }

  validateCode() {

  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  reset() {
    if (this.isValidEmail(this.email)) {

      this.toggleLoading();

      this.apiService.post('/password-recoveries/request', {
        email: this.email
      }).then((response: any) => {
        console.log(response)
        this.step = 1;
        this.toggleLoading();
      }).catch((err) => {
        this.toggleLoading();
        this.displayError("Gebruiker kon niet worden gevonden.");
      });
    }
  }

  setPassword() {

  }
}
