import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-update-password-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatInputModule, MatFormFieldModule],
  templateUrl: './update-password-dialog.component.html',
  styleUrl: './update-password-dialog.component.scss'
})
export class UpdatePasswordDialogComponent {

  public passwordRepeat: any = "";
  public password: any = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService) {
  }

  save() {
    const self = this;

    if (this.password == this.passwordRepeat) {
      this.data.password = this.password;

      this.apiService.put("/me", self.data);
    }
  }
}
