<section class="theorie mt-2 mt-lg-5 px-3 px-md-0">
    <div class="container breadcrumb">
        <div class="row">
            <div class="col-12">
                Dashboard &rsaquo; Modules &rsaquo; {{module.title}}
            </div>
        </div>
    </div>
    <div class="container mb-8">
        <div class="row">
            <!-- Content -->
            <div class="col-lg-9 text_page border border-white bg_white box_shadow_1 border_radius_std">
                <div class="inside_h1 d-flex align-items-center mb-4">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">school</span></div>
                    <h1 class="ms-3 mt-2">{{module.title}}</h1>
                </div>
                <div class="mx-3 mx-lg-5">
                    <h2>Inleiding</h2>
                    <p>{{module.intro}}</p>
                </div>
                <div class="mx-3 mx-lg-5 pt-3 mb-5 bottom_nav">
                    <h2 class="w-100"><span class="header_font_light">Index theorie:</span> {{module.title}}</h2>
                    <ul>
                        <ng-container *ngFor="let lesson of lessonHierarchy">
                            <div class="d-flex no-decoration">
                                <i (click)="lesson.collapse = false;" class="cursor"
                                    *ngIf="lesson.collapse && lesson.hasChildren">🠋</i>
                                <i (click)="lesson.collapse = true;" class="cursor"
                                    *ngIf="!lesson.collapse && lesson.hasChildren">🠊</i>
                                <a [href]="['/lesson/' + lesson.id]">
                                    <li>{{lesson.title}}
                                    </li>
                                </a>
                            </div>
                            <ng-container *ngFor="let child of lesson['children']">
                                <a *ngIf="lesson.collapse" [href]="['/lesson/' + child.id]">
                                    <li style="padding-left:24px;">
                                        {{child.title}}
                                    </li>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <!-- Index menu desktop -->
            <div class="col-lg-3 scroll_index_menu_wrap d-none d-lg-block">
                <div class="scroll_index_menu g-0 bg_lightgrey_dark box_shadow_1">
                    <div class="scroll_index_menu_top ps-4">
                        <div class="">
                            <app-progress-circle [score]="module.score"></app-progress-circle>
                        </div>
                        <a *ngIf="module['status'] == 'OPEN'" [href]="['/module/' + module_id + '/question']" class="btn btn-light btn-progress w-100">
                            <span *ngIf="module['score'] < 1">Toetsing starten</span>
                            <span *ngIf="module['score'] > 0">Toetsing hervatten</span>
                        </a>
                    </div>
                    <div class="horizontal_line_white"></div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="title_icon_float"><span class="material-symbols-outlined">book_2</span></div>
                        <h2 class="ms-3 mt-2">Theorie</h2>
                    </div>
                    <div class="mx-4 pb-3 d-flex">
                        <div class="progress-circle-ct p-2">
                            <div class="circular-trophy bg_lightgrey_std">
                                <span class="material-symbols-outlined color_green_std">book_2</span>
                            </div>
                        </div>
                        <div class="ms-3 mt-2">
                            <a class="d-block ms-1 mt-2" [href]="['/lesson/' + lessons[0].id]">&raquo; Ga naar theorie</a>
                            <!-- <a class="d-block ms-1 mt-2" href="">&raquo; Al mijn theorie</a> -->
                        </div>
                    </div>
                    <div class="horizontal_line_white"></div>
                    <div class="d-flex align-items-center mb-2">
                        <div class="title_icon_float"><span class="material-symbols-outlined">workspace_premium</span>
                        </div>
                        <h2 class="ms-3 mt-2" *ngIf="module.status == 'FINISHED'">Certificaat behaald</h2>
                        <h2 class="ms-3 mt-2" *ngIf="module.status != 'FINISHED'">Certificaat</h2>
                    </div>
                    <div class="mx-4 pb-3 d-flex">
                        <div class="progress-circle-ct p-2">
                            <div class="circular-trophy bg_lightgrey_std">
                                <span class="material-symbols-outlined color_green_std">workspace_premium</span>
                            </div>
                        </div>
                        <div class="ms-3 mt-2">
                            <a class="d-block ms-1 mt-2" *ngIf="module.status == 'FINISHED'" [href]="['/certificates']">&raquo; Certificaat bekijken</a>
                            <a class="d-block ms-1 mt-2" [href]="['/certificates']">&raquo; Naar certificaten</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Index menu mobiel -->
<div class="index_menu_button_ct d-inline d-lg-none">
    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#index_menu">
        <span class="material-symbols-outlined mb-n2">list</span><span class="d-block mt-n1">Index</span>
    </button>
</div>
<div class="modal fade" id="index_menu" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog m-3 m-sm-auto mt-sm-3">
        <div class="modal-content">
            <div class="modal_close_ct">
                <button type="button" class="btn-close text-reset" data-bs-dismiss="modal"><span
                        class="material-symbols-outlined">close</span></button>
            </div>
            <div class="scroll_index_menu g-0 bg_lightgrey_dark box_shadow_1">
                <div class="scroll_index_menu_top ps-4 py-3">
                    <div class="">
                        <app-progress-circle [score]="module.score"></app-progress-circle>
                    </div>

                    <a *ngIf="module['status'] == 'OPEN'" [href]="['/module/' + module_id + '/question']" class="btn btn-light btn-progress ms-3">
                        <span *ngIf="module['score'] < 1">Toetsing starten</span>
                        <span *ngIf="module['score'] > 0">Toetsing hervatten</span>
                    </a>
                </div>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">book_2</span></div>
                    <h2 class="ms-3 mt-2">Theorie</h2>
                </div>
                <div class="mx-4 pb-3 d-flex">
                    <div class="progress-circle-ct p-2">
                        <div class="circular-trophy bg_lightgrey_std">
                            <span class="material-symbols-outlined color_green_std">book_2</span>
                        </div>
                    </div>
                    <div class="ms-3 mt-2">
                        <a class="d-block ms-1 mt-2" [href]="['/lesson/' + lessons[0].id]">&raquo; Ga naar theorie</a>
                        <!-- <a class="d-block ms-1 mt-2" href="">&raquo; Al mijn theorie</a> -->
                    </div>
                </div>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">workspace_premium</span>
                    </div>
                    <h2 class="ms-3 mt-2" *ngIf="module.status == 'FINISHED'">Certificaat behaald</h2>
                    <h2 class="ms-3 mt-2" *ngIf="module.status != 'FINISHED'">Certificaat</h2>
                </div>
                <div class="mx-4 pb-3 d-flex">
                    <div class="progress-circle-ct p-2">
                        <div class="circular-trophy bg_lightgrey_std">
                            <span class="material-symbols-outlined color_green_std">workspace_premium</span>
                        </div>
                    </div>
                    <div class="ms-3 mt-2">
                        <a class="d-block ms-1 mt-2" *ngIf="module.status == 'FINISHED'" [href]="['/certificates']">&raquo; Certificaat bekijken</a>
                        <a class="d-block ms-1 mt-2" [href]="['/certificates']">&raquo; Naar certificaten</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<script>
    document.addEventListener("DOMContentLoaded", function () {
        var lastScrollTop = 0;
        var scrollMenu = document.querySelector(".scroll_index_menu");

        window.addEventListener("scroll", function () {
            var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                // Downscroll code
                scrollMenu.style.borderRadius = "18px 18px 18px 18px";
            } else {
                // Upscroll code
                scrollMenu.style.borderRadius = "0px 18px 18px 18px";
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        });
    });
</script>