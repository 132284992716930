<section class="theorie mt-2 mt-lg-5 px-3 px-md-0">
    <div class="container breadcrumb">
        <div class="row">
            <div class="col-12">
                Dashboard &rsaquo; Module &rsaquo; {{module.title}} &rsaquo; Theorie
            </div>
        </div>
    </div>
    <div class="container mb-8">
        <div class="row">
            <!-- Content -->
            <div class="col-lg-9 text_page border border-white bg_white box_shadow_1 border_radius_std">
                <div class="inside_h1 d-flex align-items-center mb-4">
                    <div class="title_icon_leftTop"><span class="material-symbols-outlined">account_box</span></div>
                    <h1 class="ms-3 mt-2"><span class="header_font_light">Theorie:</span> {{module.title}}</h1>
                </div>
                <div class="mx-3 mx-lg-5">
                    <h2>{{lesson.title}}</h2>

                    <div id="content" [innerHTML]="lesson.content"></div>
                </div>
                <div class="mx-3 mx-lg-5 pt-3 mb-4 bottom_nav d-flex justify-content-between">
                    <a *ngIf="canShowPreviousButton()" (click)="goPreviousLesson()" class="btn btn-light">Vorige <span
                            class="d-none d-lg-inline">hoofdstuk</span></a>
                    <a *ngIf="canShowNextButton()" (click)="goNextLesson()" class="btn btn-light">Volgende <span
                            class="d-none d-lg-inline">hoofdstuk</span></a>
                </div>
            </div>
            <!-- Index menu desktop -->
            <div class="col-lg-3 scroll_index_menu_wrap d-none d-lg-block">
                <div class="scroll_index_menu g-0 bg_lightgrey_dark box_shadow_1">
                    <div class="d-flex align-items-center mb-3">
                        <div class="title_icon_float"><span class="material-symbols-outlined">list</span></div>
                        <h2 class="ms-3 mt-2">Index</h2>
                    </div>
                    <ul class="clean_list mx-4 mb-4">
                        <!-- <a *ngFor="let lesson of lessons" [href]="['/module-preview/lesson/' + lesson.id]"><li [class]="{'active': lesson.id == lesson_id}">{{lesson.title}}</li></a> -->
                        <ng-container *ngFor="let lesson of lessonHierarchy">
                            <div class="d-flex no-decoration">
                                <i (click)="lesson.collapse = false;" class="cursor"
                                    *ngIf="lesson.collapse && lesson.hasChildren">🠋</i>
                                <i (click)="lesson.collapse = true;" class="cursor"
                                    *ngIf="!lesson.collapse && lesson.hasChildren">🠊</i>
                                <a (click)="openLesson(lesson.id)" class="pointer">
                                    <li [class.bold]="lesson.id == lesson_id">{{lesson.title}}
                                    </li>
                                </a>
                            </div>
                            <ng-container *ngFor="let child of lesson['children']">
                                <a *ngIf="lesson.collapse" (click)="openLesson(child.id)" class="pointer">
                                    <li [class.bold]="child.id == lesson_id" class="ml-5">
                                        {{child.title}}
                                    </li>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Index menu mobiel -->
<div class="index_menu_button_ct d-inline d-lg-none">
    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#index_menu">
        <span class="material-symbols-outlined mb-n2">list</span><span class="d-block mt-n1">Index</span>
    </button>
</div>
<div class="modal fade" id="index_menu" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog m-3 m-sm-auto mt-sm-3">
        <div class="modal-content">
            <div class="modal_close_ct">
                <button type="button" class="btn-close text-reset" data-bs-dismiss="modal"><span
                        class="material-symbols-outlined">close</span></button>
            </div>
            <div class="scroll_index_menu g-0 bg_lightgrey_dark box_shadow_1">
                <div class="scroll_index_menu_top ps-4 py-3">
                    <div class="">
                        <app-progress-circle [score]="module.score"></app-progress-circle>
                    </div>
                    <a [href]="['/module/' + module_id + '/question']" class="btn btn-light btn-progress ms-3">
                        <span *ngIf="module['score'] < 1">Toetsing starten</span>
                        <span *ngIf="module['score'] > 0">Toetsing hervatten</span>
                    </a>
                </div>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-3">
                    <div class="title_icon_float"><span class="material-symbols-outlined">list</span></div>
                    <h2 class="ms-3 mt-2">Index</h2>
                </div>
                <ul class="clean_list mx-4 mb-4">
                    <li>Een Inleiding</li>
                    <li class="active">Blaaskatheresatie: Procedures en Praktijk</li>
                    <li>Complicaties en Veiligheidsmaatregelen bij Blaaskatheresatie</li>
                    <li>Innovaties in Blaaskatheresatietechnologie</li>
                    <li>Zorgvuldige Selectie van Blaaskatheters: Een Gids</li>
                    <li>Het Belang van Hygiëne in Blaaskatheresatie</li>
                    <li>Blaaskatheresatie in Diverse Medische Specialismen</li>
                </ul>
                <div class="horizontal_line_white"></div>
                <div class="d-flex align-items-center mb-2">
                    <div class="title_icon_float"><span class="material-symbols-outlined">workspace_premium</span></div>
                    <h2 class="ms-3 mt-2">Certificaat behaald</h2>
                </div>
                <div class="mx-4 pb-3 d-flex">
                    <div class="progress-circle-ct p-2">
                        <div class="circular-trophy bg_lightgrey_std">
                            <span class="material-symbols-outlined color_green_std">workspace_premium</span>
                        </div>
                    </div>
                    <div class="ms-3 mt-2">
                        <a href="" class="btn btn-light btn-sm">Certificaat bekijken</a>
                        <a class="d-block ms-1 mt-2" href="">&raquo; Alle certificaten</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    document.addEventListener("DOMContentLoaded", function () {
        var lastScrollTop = 0;
        var scrollMenu = document.querySelector(".scroll_index_menu");

        window.addEventListener("scroll", function () {
            var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                // Downscroll code
                scrollMenu.style.borderRadius = "18px 18px 18px 18px";
            } else {
                // Upscroll code
                scrollMenu.style.borderRadius = "0px 18px 18px 18px";
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        });
    });
</script>