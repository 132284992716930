<section class="toetsing mt-2 mt-lg-5 px-3 px-md-0">
    <div class="container mb-5">
        <div class="row mb-n3">
            <div class="col-lg-12 text-start text-md-end">
                <div class="d-flex d-md-inline justify-content-between align-items-center">
                    <h1 class="d-inline-block me-2"><span
                            class="header_font_light d-block d-sm-inline-block">Toetsing:&nbsp;</span>{{module.title}}
                    </h1>
                    <app-progress-circle [score]="module.score"></app-progress-circle>
                </div>
            </div>
        </div>
        <div *ngIf="!isFinished"
            class="row question_answer border border-white bg_white box_shadow_1 border_radius_std">
            <!-- Content -->
            <div class="col-lg-6 d-flex align-items-center px-4 px-lg-6">
                <div class="vraag my-5 my-lg-0">
                    <span *ngIf="question.type == 'DEFAULT'">Maak de zin af...</span>
                    <h2>{{question.title}}</h2>

                    <ng-container *ngIf="hasBeenAnswered">
                        <span class="answer_succes_notification" *ngIf="isCorrect">Goed zo je hebt deze vraag juist
                            beantwoord!</span>
                        <span class="answer_fail_notification" *ngIf="!isCorrect">Jammer je hebt deze vraag onjuist
                            beantwoord!</span>
                    </ng-container>
                </div>
            </div>
            <div
                class="col-lg-6 d-flex align-items-center px-3 px-lg-6 py-3 py-lg-3 bg_lightgrey_std border_radius_std_topRight_bottomRight_desktop border_radius_std_bottomLeft_bottomRight_mobile">
                <div class="answer w-100">

                    <div *ngFor="let answer of question.answer_options; index as i" class="form-check">
                        <div *ngIf="hasBeenAnswered && correctAnswerId === answer['id']"
                            class="answer_fail_goodanswer_balloon">
                            Het juiste antwoord was:
                        </div>

                        <input [disabled]="hasBeenAnswered" (click)="selectAnswer(answer)" class="form-check-input" type="radio"
                            name="flexRadioDefault" id="answer-{{answer['id']}}">
                        <label class="form-check-label" for="answer-{{answer['id']}}">
                            {{indexToLetter(i)}}. {{answer.title}}
                            <ng-container *ngIf="hasBeenAnswered && correctAnswerId === answer['id']">
                                <span class="answer_text">
                                    <span *ngIf="isCorrect">Goed zo!</span>
                                    <span *ngIf="!isCorrect">Jammer, dit is het juiste antwoord</span>
                                    Je kan hier onderaan de pagina de bijbehorende theorie vinden.</span>
                                <span class="answer_text">{{description}}</span>
                            </ng-container>
                        </label>
                    </div>

                    <div class="w-100 text-end" *ngIf="hasBeenAnswered">
                        <div (click)="nextQuestion()"
                            class="btn btn-secondary d-inline-flex align-items-center py-2 ps-3">Door naar de volgende
                            vraag <span class="material-symbols-outlined ms-1">arrow_circle_right</span></div>
                    </div>
                </div>

                <!-- image answer -->
                <!-- <div class="answer w-100">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                            <div class="d-flex align-items-center">A:&nbsp;<img class="img-fluid ms-3 pe-3"
                                    src="img/reanimatie_1.jpg"></div>
                        </label>
                    </div>
                    <div class="form-check">
                        <div class="answer_fail_goodanswer_balloon d-none">Het juiste antwoord was:</div>
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                            <div class="d-flex align-items-center">B:&nbsp;<img class="img-fluid ms-3 pe-3"
                                    src="img/reanimatie_1.jpg"></div>
                            <span class="answer_text d-none">Goed zo je gaf het juiste antwoord. Je kan hier onderaan de
                                pagina de bijbehorende theorie vinden.</span>
                            <span class="answer_text d-none">Dit was het juiste antwoord, omdat lorem ipsum dolor sit
                                amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                                dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
                                tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</span>
                        </label>
                    </div>
                    <div class="w-100 text-end d-none">
                        <div class="btn btn-secondary d-inline-flex align-items-center py-2 ps-3">Door naar de volgende
                            vraag <span class="material-symbols-outlined ms-1">arrow_circle_right</span></div>
                    </div>
                </div> -->
            </div>
        </div>

        <div *ngIf="isFinished"
            class="row question_answer border border-white bg_white box_shadow_1 border_radius_std">
            <!-- Content -->
            <div class="col-lg-12 d-flex align-items-center px-4 px-lg-6">
                <div class="center my-5 my-lg-0">
                    <h2>Je hebt '{{module.title}}' afgerond, klik <a [href]="['/']" class="btn btn-secondary">hier</a> om terug naar dashboard te gaan.</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="hasBeenAnswered && lesson" class="description-container">
    <div class="container bg_lightgrey_std border border-white bg_white box_shadow_1 border_radius_std py-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="mx-3 mx-lg-5">
                    <h2>{{lesson.title}}</h2>
                    <div id="content" [innerHTML]="lesson.content"></div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="toetsing_controls Xbox_shadow_1 position-fixed bottom-0 w-100 py-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <!-- <a href="" class="btn btn-light d-inline-flex align-items-center me-2"><span
                        class="material-symbols-outlined me-1">pause</span> Pauzeren</a> -->
                <a [href]="['/module/' + module_id]" class="btn btn-light d-inline-flex align-items-center"><span
                        class="material-symbols-outlined me-1">book_2</span> Naar theorie</a>
            </div>
        </div>
    </div>
</section>