<section class="vh-100 fullheight">
    <div class="container pb-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
                <div class="card" style="border-radius: 1rem;">
                    <div class="row g-0">
                        <div class="col-md-6 col-lg-5 d-none d-md-block">
                            <img src="assets/img/login_bg.webp" alt="login form" class="img-fluid"
                                style="height: 100%; object-fit: cover; object-position: left; border-radius: 1rem 0 0 1rem;" />
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">

                                <div class="d-flex align-items-center mb-3 pb-1">
                                    <span class="h1 fw-bold mb-0">
                                        <img src="assets/img/hdgm_e-learning_logo.svg" width="200">
                                    </span>
                                </div>

                                <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Inloggen op HDGM</h5>

                                <div data-mdb-input-init class="form-outline mb-4">
                                    <label class="form-label">Email</label>
                                    <input [(ngModel)]="email" type="email" class="form-control form-control-lg" />
                                </div>

                                <div data-mdb-input-init class="form-outline mb-4">
                                    <label class="form-label">Wachtwoord</label>
                                    <input (keyup.enter)="login()" [(ngModel)]="password" type="password"
                                        class="form-control form-control-lg" />
                                </div>

                                <div class="pt-1 mb-4">
                                    <button (click)="login()"
                                        [disabled]="email.length < 4 || password.length < 4 || loading"
                                        class="btn btn-dark btn-lg btn-block" type="button">Login</button>
                                </div>

                                <b><a class="small text-muted" [href]="['/forgot-password']">Wachtwoord vergeten?</a></b>
                                <p class="mb-5 pb-lg-2" style="color: #000000;">Heb je nog geen account? <b><a
                                            [href]="['/register']" style="color: #b20029;">Registreren</a></b></p>
                                <a href="https://hdgm.nl/algemene-voorwaarden" target="_blank" class="small text-muted">Gebruiksvoorwaarden</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="alert alert-danger" role="alert" [ngClass]="{'showError': showError}">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
        viewBox="0 0 16 16">
        <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg> De inloggegevens zijn incorrect.
</div>