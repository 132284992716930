import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiService } from '../../services/api.service';
import { FormsModule } from '@angular/forms';

import { ProgressCircleComponent } from '../../components/progress-circle/progress-circle.component';
import { environment } from '../../../environments/environment';
import { UploadCertificateComponent } from '../../components/upload-certificate/upload-certificate.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-certificates',
  standalone: true,
  imports: [CommonModule, FormsModule, ProgressCircleComponent],
  templateUrl: './certificates.component.html',
  styleUrl: './certificates.component.scss'
})
export class CertificatesComponent {
  certificates: any;
  apiBaseURL: any;
  searchVal: any = "";

  constructor(private apiService: ApiService, public dialog: MatDialog) {
  }

  canShow(str: String) {
    if (str.toLowerCase().includes(this.searchVal.toLowerCase())) {
      return true;
    }

    return false;
  }

  uploadFile() {
    let self = this;
    let dialogRef = this.dialog.open(UploadCertificateComponent, {
    }).afterClosed().subscribe(result => {
      self.getCertificates();
    });
  }

  deleteCertificate(certificate: any) {
    this.apiService.delete("/me/certificate/" + certificate.external_hash).then((data) => {
      this.getCertificates();
    });
  }

  ngOnInit(): void {
    this.apiBaseURL = environment.api_url;
    this.getCertificates();
  }

  getManualCertCount() {
    let i = 0;
    this.certificates.forEach((cert: any) => {
      if(cert.manually_uploaded) {
        i++;
      }
    });

    return i;
  }

  getCertificates() {
    this.apiService.get("/me/certificates").then((data) => {
      this.certificates = data;
    })
  }
}
