<div class="screenWide">
    <h2 mat-dialog-title>Nieuw abonnement</h2>
    <div>
        <button mat-button mat-dialog-close>Sluiten</button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <!-- <div style="margin-bottom: 10px;">
        Reeds ingeschreven voor deze praktijkdag
    </div> -->
    <div class="row">
        <div class="col-md-12 mb-4">
            <label class="form-label">Selecteer je niveau *</label>
            <select [(ngModel)]="selectedSubscription" class="form-select mb-2">
                <option *ngFor="let subscription of subscriptionOptions" [value]="subscription.id">{{subscription.name}}
                    -
                    {{toDutchEurFormat(subscription.amount)}}</option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-2">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="invalidCheck" required>
                <label class="form-check-label" for="invalidCheck">
                    Ik ga akkoord met de algemene voorwaarden en geef toestemming voor de jaarlijkse automatische<br>
                    incasso voor het HDGM abonnement.
                </label>
            </div>
        </div>

        <div *ngIf="showError" style="color: red;">
            {{errorMessage}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <ng-container>
        <button (click)="subscribe()" class="attendButton mr-2" mat-button>Inschrijven</button>
    </ng-container>
</mat-dialog-actions>