import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})

export class LoginComponent {
  email = "";
  password = "";

  loading = false;

  showError = false;

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router) {

  }

  login() {
    this.apiService.login({
      email: this.email,
      password: this.password
    }).then((response: any) => {
      this.storageService.setAccessToken(response['access_token']);
      this.router.navigate(["/"]);

    }).catch((err) => {
      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 2500);
    });
  }
}
