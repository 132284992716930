import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiService } from '../../services/api.service';
import { FormsModule } from '@angular/forms';

import { ProgressCircleComponent } from '../../components/progress-circle/progress-circle.component';

@Component({
  selector: 'app-modules',
  standalone: true,
  imports: [CommonModule, FormsModule, ProgressCircleComponent],
  templateUrl: './modules.component.html',
  styleUrl: './modules.component.scss'
})
export class ModulesComponent implements OnInit {

  modules: any;
  activeModules: any;

  search = "";

  errorMessage = "";
  showError = false;

  constructor(private apiService: ApiService) {
  }

  ngOnInit(): void {

    this.apiService.get("/me/modules/available").then((data) => {
      this.modules = data;
    });

    this.apiService.get("/me/modules").then((data) => {
      this.activeModules = data;
    })
  }

  canDisplaySearch(moduleName: string) {
    if (this.search.length == 0 || moduleName.toLowerCase().includes(this.search.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  activateModule(id: any) {
    this.apiService.post("/me/module/start/" + id, {}).then((data: any) => {
      window.location.href = "/module/" + data['id'];
    }).catch((data: any) => {
      this.errorMessage = data.error.message;
      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 3000);
    });
  }
}
